import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './facebookAlbumPromociones.scss';

const FacebookAlbumPromociones = ({ accessToken, albumId }) => {
    const [photos, setPhotos] = useState([]);
    /////Nombre de album en cada uno de las fotos
    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                console.log(albumId);
                const response = await axios.get(`https://graph.facebook.com/${albumId}/photos`, {
                    params: {
                        access_token: accessToken,
                        fields: 'id,source,created_time',
                    }
                });
                const sortedPhotos = response.data.data.sort((a, b) => new Date(b.created_time) - new Date(a.created_time));
                const recentPhotos = sortedPhotos.slice(0, 12);

                // Guardar fotos y fecha de última actualización en un solo objeto
                const dataToStore = {
                    photos: recentPhotos,
                    lastUpdate: new Date().getTime()
                };
                localStorage.setItem("imagesFBPromociones", JSON.stringify(dataToStore));
                setPhotos(recentPhotos);
            } catch (error) {
                console.error('Error fetching photos:', error);
            }
        };

        const storedData = JSON.parse(localStorage.getItem("imagesFBPromociones"));
        const currentTime = new Date().getTime();

        // Verificar el formato del localStorage y actualizar si es necesario
        const isValidData = storedData && typeof storedData === 'object' && 'photos' in storedData && 'lastUpdate' in storedData;

        // Verificar si han pasado más de 2 días o si es para la prueba de 10 minutos
        if (!isValidData || currentTime - storedData.lastUpdate > 2 * 24 * 60 * 60 * 1000) {
            fetchPhotos();
        } else {
            setPhotos(storedData.photos);
        }
    }, [accessToken, albumId]);
    return (
        <div className="photo-container">
            {photos.map(photo => (
                <img key={photo.id} src={photo.source} alt="Facebook Album" />
            ))}
        </div>

    );
};

export default FacebookAlbumPromociones;